import React from "react"
import styled from "styled-components"
import conBack from "../../static/contents/contents-back.png"
import conBack2 from "../../static/contents/contents-back-480.png"
const Container = styled.div`
  .content-main-title-text-part {
    width: 60vw;
    position: absolute;
    left: 50vw;
    margin-left: -30vw;
  }
  .content-main-title-text-part p {
    font-size: 1.3vw;
    line-height: 1.5;
  }

  .content-main-title-text-part p:first-child {
    font-size: 2.3vw;
    margin: 6vw 0 2vw;
    font-weight: 300;
  }

  .content-main-title-text-part p span {
    font-weight: bold;
  }

  .contents-background-img img {
    width: 100vw;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .content-main-title-text-part {
      width: 80vw;
      margin-left: -40vw;
    }

    .content-main-title-text-part p {
      font-size: 4vw;
      line-height: 1.47;
    }

    .content-main-title-text-part p:first-child {
      font-size: 7vw;
      margin: 9vw 0 2vw;
    }
  }
`

const contents1 = () => {
  return (
    <Container>
      <div className="content-main-title-text-part">
        <p>
          공간샘 <span>목표를 돕는 공간</span>
        </p>
        <p>
          공간샘에는 고객의 목표 달성에 도움을 주는 <br className="show-480" />
          <span>다양한 콘텐츠</span>가 준비되어 있습니다.
          <br />
          스터디카페는 그저 공부하는 공간이 아닌 <br className="show-480" />
          <span>목표를 이루는 공간</span>
          이어야 하기 때문입니다.
        </p>
      </div>
      <div className="contents-background-img">
        <img className="hide-480" src={conBack} alt="contents배경이미지" />
        <img className="show-480" src={conBack2} alt="contents배경이미지" />
      </div>
    </Container>
  )
}

export default contents1
