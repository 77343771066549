import React from "react"
import { Link } from "react-scroll"
import styled from "styled-components"
import Letmeup from "../../static/contents/letmeup2.png"
import Letmeup_title from "../../static/contents/title-letmeup.png"
import Let_text1 from "../../static/contents/text-list/let-text1.png"
import Let_text2 from "../../static/contents/text-list/let-text2.svg"
import Let_title_480 from "../../static/contents/480/let-title.png"
import Let_text_480 from "../../static/contents/480/let-text.svg"
import Suhack from "../../static/contents/suhackssabu2.png"
import Suhack_title from "../../static/contents/title-suhack.png"
import Suhack_text1 from "../../static/contents/text-list/suhack-text1.png"
import Suhack_text2 from "../../static/contents/text-list/suhack-text2.svg"
import FastCam from "../../static/contents/fastCam2.png"
import Fast_title from "../../static/contents/title-fast.png"
import Fast_text1 from "../../static/contents/text-list/fast-text1.png"
import Fast_text2 from "../../static/contents/text-list/fast-text2.svg"
import Fast_title_480 from "../../static/contents/480/fast-title.png"
import Fast_text_480 from "../../static/contents/480/fast-text.svg"
import Packmun from "../../static/contents/pack2.png"
import Pack_title from "../../static/contents/title-pack.png"
import Park_text1 from "../../static/contents/text-list/park-text1.png"
import Park_text2 from "../../static/contents/text-list/park-text2.svg"
import Park_title_480 from "../../static/contents/480/park-title.png"
import Park_text_480 from "../../static/contents/480/park-text.svg"
import Gugpung from "../../static/contents/gugpung2.png"
import Gugpung_title from "../../static/contents/title-gugpung.png"
import Gug_text1 from "../../static/contents/text-list/gug-text1.png"
import Gug_text2 from "../../static/contents/text-list/gug-text2.svg"
import Gug_title_480 from "../../static/contents/480/gug-title.png"
import Gug_text_480 from "../../static/contents/480/gug-text.svg"
import JobShop from "../../static/contents/Jobshop2.png"
import Hak_title from "../../static/contents/title-hak.png"
import Hak_text1 from "../../static/contents/text-list/hak-text1.png"
import Hak_text2 from "../../static/contents/text-list/hak-text2.svg"
import Hak_title_480 from "../../static/contents/480/hak-title.png"
import Hak_text_480 from "../../static/contents/480/hak-text.svg"
import Time from "../../static/contents/time2.png"
import Time_title from "../../static/contents/title-time.png"
import Time_text1 from "../../static/contents/text-list/time-text1.png"
import Time_text2 from "../../static/contents/text-list/time-text2.svg"
import Time_title_480 from "../../static/contents/480/time-title.png"
import Time_text_480 from "../../static/contents/480/time-text.svg"
import gobtn from "../../static/contents/go-button.svg"
import goConsulting from "../../static/contents/goConsulting.png"
import goEvent from "../../static/contents/goEvent.png"
import rightbtn from "../../static/contents/480/rightarrow.svg"
const Container = styled.div`
  padding-bottom: 6vw;
  .contents-list-btn {
    width: 80vw;
    margin: -6.5vw auto 0;
    display: flex;
    justify-content: space-between;
  }
  .contents-list-btn img {
    width: 10vw;
  } 

  .title-list {
    display: flex;
    width: 75vw;
    margin: 3vw auto;
    justify-content: flex-end;
    align-items: center;
  }
  .title-list-img {
    width: 30vw;
    height: 22vw;
    display: block;
    overflow: hidden;
    // box-shadow: 1px 2px 4px lightgrey;
  }

  .title-list-img img {
    height: 22vw;
    display: block;
    float: right;
    margin-right: 3.4vw;
  }

  .title-list-text {
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contents-sami-text img {
    width: 1.5vw;
    vertical-align: middle;
  }

  .content-list-btn {
    width: 15vw;
    margin-left: -1vw;
    margin-top: -1vw;
  }

  .fastcam-text {
    color: #da3c50;
  }

  .pack-text {
    color: #f48d27;
  }

  .gugpung-text {
    color: #2354a0;
  }

  .hak-text {
    color: #10b6b0;
  }

  .time-text {
    color: #37325b;
  }

  hr {
    width: 65vw;
  }

  .choice-listzone {
    box-shadow: 0 0 9px 0 rgb(0 0 0 / 50%);
    border-radius: 0.5vw;
  }

  .title-text1 {
    width: 32vw;
  }

  .let-text2 {
    width: 35vw;
  }

  .text2 {
    margin: 2vw 0;
  }

  .fast-text2 {
    width: 43vw;
  }

  .park-text2 {
    width: 35vw;
  }

  .gug-text2,
  .hak-text2 {
    width: 37vw;
  }

  .time-text2 {
    width: 33vw;
  }

  .show-480 {
    display: none;
  }

  .consulting-btn {
    width: 24vw;
    margin-top: -1.5vw;
  }

  .letmeup-btn-all {
    display: flex;
    margin-left:-2.3vw;
  }

  .suhack-text2 {
    width:24vw;
  }

  .event-btn {
    width:16.8vw;
    margin-top:-1.5vw;
  }
  @media screen and (max-width: 480px) {
    padding-bottom: 0;
    .contents-list-btn img {
      width: 24.5vw;
      margin-bottom: 3vw;
      box-shadow: 0 0 4px lightgrey;
      border-radius: 4vw;
    }

    .contents-list-btn {
      flex-wrap: wrap;
      margin: -9.5vw auto 0;
    }

    .choice-listzone {
      box-shadow: 0 0 20px 0 rgb(0 0 0 / 50%) !important;
    }

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    hr {
      width: 100vw;
    }

    .contents-480-title img {
      width: 70vw;
      margin: 2vw auto;
      display: block;
    }

    .contents-480-title {
      display: flex;
      width: 80vw;
      margin: 0 auto;
      align-items: end;
    }

    .arrow {
      width: 2vw !important;
      margin-top: 4vw !important;
    }

    .contents-let-detail img {
      width: 89vw;
      margin-top: 2vw;
    }

    img.let-textpart {
      width: 58vw;
      display: block;
      margin: 2vw 0 2vw 12.5vw;
    }

    img.content-list-btn {
      margin: 0 auto !important;
      display: block !important;
      width: 30vw !important;
    }

    .contents-fast-detail img {
      width: 80vw;
      display: block;
      margin: 5vw auto 0;
    }

    img.fast-textpart {
      width: 60vw;
      display: block;
      margin: 5vw 0 2vw 12.5vw;
    }

    img.park-textpart {
      width: 72vw;
      display: block;
      margin: 5vw 0 2vw 12.5vw;
    }

    img.hak-textpart {
      width: 64vw;
      display: block;
      margin: 5vw 0 2vw 12.5vw;
    }

    .no-print {
      display: none;
    }

    .show-detail {
      display: block;
    }

    .transform {
      transform: rotate(90);
    }

    .consulting-btn {
      width: 45vw !important;
      margin: 0 auto !important;
      display: block !important;
    }
    .suhack-textpart {
      width: 80vw;
      display: block;
      margin: 2vw auto 2vw;
    }

    .contents-su-detail img {
      width: 80vw;
      margin: 2vw auto 5vw;
      display: block;  
    }

    .event-btn {
      width:34.5vw !important;
      padding-top: 1.5vw;
      margin:-2vw auto 0!important;
    }
  }
`
if (typeof window !== "undefined") {
  var listzone = document.getElementsByClassName("list-btn")
  var no_print = document.getElementsByClassName("no-print")
}

const handleClick = (event: any) => {
  for (var i = 0; i < listzone.length; i++) {
    listzone[i].classList.remove("choice-listzone")
  }

  event.classList.add("choice-listzone")
}

const handleClick_detail = (event: any) => {
  for (var i = 0; i < listzone.length; i++) {
    no_print[i].classList.remove("show-detail")
  }
  event.target.parentNode.nextSibling.classList.add("show-detail")
  event.target.classList.add("show-detail")
}

const contents2 = () => {
  return (
    <Container>
      <div className="contents-list-btn">
        <Link to="1" spy={true} smooth={true}>
          <img
            className="list-btn"
            src={Letmeup}
            alt="list-one"
            onClick={({ target }) => handleClick(target)}
          />
        </Link>
        <Link to="7" spy={true} smooth={true}>
          <img
            className="list-btn"
            src={Suhack}
            alt="list-seven"
            onClick={({ target }) => handleClick(target)}
          />
        </Link>
        <Link to="2" spy={true} smooth={true}>
          <img
            className="list-btn"
            src={FastCam}
            alt="list-two"
            onClick={({ target }) => handleClick(target)}
          />
        </Link>
        <Link to="3" spy={true} smooth={true}>
          <img
            className="list-btn"
            src={Packmun}
            alt="list-three"
            onClick={({ target }) => handleClick(target)}
          />
        </Link>
        <Link to="4" spy={true} smooth={true}>
          <img
            className="list-btn"
            src={Gugpung}
            alt="list-four"
            onClick={({ target }) => handleClick(target)}
          />
        </Link>
        <Link to="5" spy={true} smooth={true}>
          <img
            className="list-btn"
            src={JobShop}
            alt="list-five"
            onClick={({ target }) => handleClick(target)}
          />
        </Link>
        <Link to="6" spy={true} smooth={true}>
          <img
            className="list-btn"
            src={Time}
            alt="list-six"
            onClick={({ target }) => handleClick(target)}
          />
        </Link>
        
      </div>
      <hr className="show-480" id="1" />
      <div className="title-list hide-480">
        <div className="title-list-img">
          <img src={Letmeup_title} alt="렛미업 타이틀" />
        </div>
        <div className="title-list-text">
          <img className="title-text1" src={Let_text1} alt="렛미업1" />
          <img className="let-text2 text2" src={Let_text2} alt="렛미업2" />
          <div className="letmeup-btn-all">
          <img
          className="consulting-btn"
          src={goConsulting}
          alt="렛미업 컨설팅 바로가기"
          onClick={() => window.open("https://consulting.letmeup.co.kr/", "_blank")}
        />
          <img
            className="content-list-btn"
            src={gobtn}
            alt="자세히보기"
            onClick={() => window.open("https://letmeup.co.kr/", "_blank")}
          />
        </div>
        </div>
      </div>
      <div className="contents-480-title show-480">
        <img src={Let_title_480} alt="렛미업" />
        <img
          className="arrow"
          src={rightbtn}
          alt="화살표"
          onClick={handleClick_detail}
        />
      </div>
      <div className="show-480 no-print contents-let-detail">
        <img src={Letmeup_title} alt="렛미업" style={{ marginTop: "-12vw" }} />
        <img className="let-textpart" src={Let_text_480} alt="렛미업" />
        <div className="letmeup-btn-all">
        <img
          className="consulting-btn"
          src={goConsulting}
          alt="렛미업 컨설팅 바로가기"
          onClick={() => window.open("https://consulting.letmeup.co.kr/", "_blank")}
        />
        <img
          className="content-list-btn"
          src={gobtn}
          alt="자세히보기"
          onClick={() => window.open("https://letmeup.co.kr/", "_blank")}
        />
        </div>
      </div>
      <hr id="7" />
      <div className="title-list hide-480">
        <div className="title-list-img">
          <img src={Suhack_title} alt="패스트 타이틀" />
        </div>
        <div className="title-list-text">
          <img className="title-text1 suhack-text2" src={Suhack_text1} alt="수학1" />
          <img className="fast-text2 text2" src={Suhack_text2} alt="수학2" />
          <div className="letmeup-btn-all">
          <img
          className="event-btn"
          src={goEvent}
          alt="이벤트 바로가기"
          onClick={() => window.open("http://mssabu.net/kut/page/app_talk_all.html?nocl=1&svctype=36", "_blank")}
        />
        <img
          className="content-list-btn"
          src={gobtn}
          alt="자세히보기"
          onClick={() => window.open("https://mssabu.net", "_blank")}
        />
        </div>
        </div>
      </div>
      <div className="contents-480-title show-480">
        <img src={Suhack_text1} alt="렛미업" />
        <img
          className="arrow"
          src={rightbtn}
          alt="화살표"
          onClick={handleClick_detail}
        />
      </div>
      <div className="show-480 no-print contents-su-detail">
        <img src={Suhack_title} alt="수학싸부"/>
        <img className="suhack-textpart" src={Suhack_text2} alt="수학싸부" />
        <div className="letmeup-btn-all">
        <img
          className="event-btn"
          src={goEvent}
          alt="이벤트 바로가기"
          onClick={() => window.open("http://mssabu.net/kut/page/app_talk_all.html?nocl=1&svctype=36", "_blank")}
        />
        <img
          className="content-list-btn"
          src={gobtn}
          alt="자세히보기"
          onClick={() => window.open("https://mssabu.net", "_blank")}
        />
        </div>
      </div>
      <hr id="2" />
      <div className="title-list hide-480">
        <div className="title-list-img">
          <img src={Fast_title} alt="패스트 타이틀" />
        </div>
        <div className="title-list-text">
          <img className="title-text1" src={Fast_text1} alt="패스트1" />
          <img className="fast-text2 text2" src={Fast_text2} alt="패스트2" />
          <img
            className="content-list-btn"
            src={gobtn}
            alt="자세히보기"
            onClick={() =>
              window.open(
                "https://saemcafe.com/%ea%b3%b5%ea%b0%84%ec%83%98x%ed%8c%a8%ec%8a%a4%ed%8a%b8%ec%ba%a0%ed%8d%bc%ec%8a%a4-3/",
                "_blank"
              )
            }
          />
        </div>
      </div>
      <div className="contents-480-title show-480">
        <img src={Fast_title_480} alt="패스트" />
        <img
          className="arrow"
          src={rightbtn}
          alt="화살표"
          onClick={handleClick_detail}
        />
      </div>
      <div className="show-480 no-print contents-fast-detail">
        <img src={Fast_title} alt="패스트" />
        <img className="fast-textpart" src={Fast_text_480} alt="패스트" />
        <img
          className="content-list-btn"
          src={gobtn}
          alt="자세히보기"
          onClick={() =>
            window.open(
              "https://saemcafe.com/%ea%b3%b5%ea%b0%84%ec%83%98x%ed%8c%a8%ec%8a%a4%ed%8a%b8%ec%ba%a0%ed%8d%bc%ec%8a%a4-3/",
              "_blank"
            )
          }
        />
      </div>
      <hr id="3" />
      <div className="title-list hide-480">
        <div className="title-list-img">
          <img src={Pack_title} alt="박문각 타이틀" />
        </div>
        <div className="title-list-text">
          <img className="title-text1" src={Park_text1} alt="박문각1" />
          <img className="park-text2 text2" src={Park_text2} alt="박문각2" />
          <img
            className="content-list-btn"
            src={gobtn}
            alt="자세히보기"
            onClick={() =>
              window.open(
                "http://www.pmg.co.kr/User/studyroom/new/main_sm.asp",
                "_blank"
              )
            }
          />
        </div>
      </div>
      <div className="contents-480-title show-480">
        <img src={Park_title_480} alt="박문각" />
        <img
          className="arrow"
          src={rightbtn}
          alt="화살표"
          onClick={handleClick_detail}
        />
      </div>
      <div className="show-480 no-print contents-fast-detail">
        <img src={Pack_title} alt="박문각" />
        <img className="park-textpart" src={Park_text_480} alt="박문각" />
        <img
          className="content-list-btn"
          src={gobtn}
          alt="자세히보기"
          onClick={() =>
            window.open(
              "http://www.pmg.co.kr/User/studyroom/new/main_sm.asp",
              "_blank"
            )
          }
        />
      </div>
      <hr id="4" />
      <div className="title-list hide-480">
        <div className="title-list-img">
          <img src={Gugpung_title} alt="국풍타이틀" />
        </div>
        <div className="title-list-text">
          <img className="title-text1" src={Gug_text1} alt="국풍1" />
          <img className="gug-text2 text2" src={Gug_text2} alt="국풍2" />
          <img
            className="content-list-btn"
            src={gobtn}
            alt="자세히보기"
            onClick={() =>
              window.open("https://iap2000.com/selection_test_ev", "_blank")
            }
          />
        </div>
      </div>
      <div className="contents-480-title show-480">
        <img src={Gug_title_480} alt="국풍" />
        <img
          className="arrow"
          src={rightbtn}
          alt="화살표"
          onClick={handleClick_detail}
        />
      </div>
      <div className="show-480 no-print contents-fast-detail">
        <img src={Gugpung_title} alt="국풍" />
        <img className="fast-textpart" src={Gug_text_480} alt="국풍" />
        <img
          className="content-list-btn"
          src={gobtn}
          alt="자세히보기"
          onClick={() =>
            window.open("https://iap2000.com/selection_test_ev", "_blank")
          }
        />
      </div>
      <hr id="5" />
      <div className="title-list hide-480">
        <div className="title-list-img">
          <img src={Hak_title} alt="학쫑 타이틀" />
        </div>
        <div className="title-list-text">
          <img className="title-text1" src={Hak_text1} alt="학쫑1" />
          <img className="hak-text2 text2" src={Hak_text2} alt="학쫑2" />
          <img
            className="content-list-btn"
            src={gobtn}
            alt="자세히보기"
            onClick={() => window.open("https://mall.hakzzong.com/", "_blank")}
          />
        </div>
      </div>
      <div className="contents-480-title show-480">
        <img src={Hak_title_480} alt="학쫑" style={{ marginLeft: "-1vw" }} />
        <img
          className="arrow"
          src={rightbtn}
          alt="화살표"
          onClick={handleClick_detail}
        />
      </div>
      <div className="show-480 no-print contents-fast-detail">
        <img src={Hak_title} alt="학쫑" />
        <img className="hak-textpart" src={Hak_text_480} alt="학쫑" />
        <img
          className="content-list-btn"
          src={gobtn}
          alt="자세히보기"
          onClick={() => window.open("https://mall.hakzzong.com/", "_blank")}
        />
      </div>
      <hr id="6" />
      <div className="title-list hide-480">
        <div className="title-list-img">
          <img src={Time_title} alt="열공시간 타이틀" />
        </div>
        <div className="title-list-text">
          <img className="title-text1" src={Time_text1} alt="열공1" />
          <img className="time-text2 text2" src={Time_text2} alt="열공2" />
          <img
            className="content-list-btn"
            src={gobtn}
            alt="자세히보기"
            onClick={() =>
              window.open("https://www.flipfocus.co.kr/", "_blank")
            }
          />
        </div>
      </div>
      <div className="contents-480-title show-480">
        <img src={Time_title_480} alt="열공" />
        <img
          className="arrow"
          src={rightbtn}
          alt="화살표"
          onClick={handleClick_detail}
        />
      </div>
      <div className="show-480 no-print contents-fast-detail">
        <img src={Time_title} alt="열공" />
        <img className="hak-textpart" src={Time_text_480} alt="열공" />
        <img
          className="content-list-btn"
          src={gobtn}
          alt="자세히보기"
          onClick={() => window.open("https://www.flipfocus.co.kr/", "_blank")}
        />
      </div>
      <hr className="show-480" />
    </Container>
  )
}

export default contents2
