import React from "react"
import Layout from "../components/Layout/Layout"

import Content1 from "../components/contents/contents1"
import Content2 from "../components/contents/contents2"
import styled from "styled-components"

const Container = styled.div`
  overflow-x: hidden;
`
const ContentsPage = () => {
  return (
    <Container>
      <Layout>
        <Content1 />
        <Content2 />
      </Layout>
    </Container>
  )
}

export default ContentsPage
